<template>
  <div class="sentiments-word-cloud">
    <word-cloud-component :words="wordsList" :height="300" />
  </div>
</template>

<script>
// Import helper function
import colors from "@/helpers/colors"

// Import children components
const WordCloudComponent = () => import(/* webpackChunkName: "word-cloud-component" */ "@/components/common/WordCloud.vue")

// Export the SFC
export default {
  // Name of the component
  name: "WordCloud",

  // Accept incoming data from parent
  props: {
    words: {
      type: Array,
      required: true
    }
  },

  // Register the components
  components: {
    WordCloudComponent
  },

  // Define readonly computable variables
  computed: {
    /**
     * Get all the words to be shown in the word cloud
     *
     * @returns {Array}
     */
    wordsList() {
      const words = this.words.map((item) => ({
        ...item,
        weight: item.score * item.frequency
      }))

      const positiveWords = words
        .filter((item) => item.score > 0)
        .sort((a, b) => b.weight - a.weight)
        .slice(0, 50)
        .map((item) => ({
          text: item.word,
          size: Math.round(Math.abs(item.weight) * 20),
          color: colors.darken(item.score > 0 ? "#00ff00" : "#ff0000", Math.abs(item.score * 80))
        }))

      const negativeWords = words
        .filter((item) => item.score < 0)
        .sort((a, b) => a.weight - b.weight)
        .slice(0, 50)
        .map((item) => ({
          text: item.word,
          size: Math.round(Math.abs(item.weight) * 40),
          color: colors.darken(item.score > 0 ? "#00ff00" : "#ff0000", Math.abs(item.score * 80))
        }))

      return [...negativeWords, ...positiveWords]
    }
  }
}
</script>

<style lang="stylus" scoped>
.sentiments-word-cloud
  display block
  width 100%
  height 300px
</style>
